import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import RecordItem from "../components/Recordings/RecordItem"
import { Container } from "reactstrap"
import BurgerMenu from "../components/MobileNavigation"

import Title from "../components/Title"
import StyledHero from "../components/StyledHero"

import style from "../components/mobile-navi-style"

const recordings = ({ data }) => {
  const MenuWrapper = style
  return (
    <>
      <MenuWrapper className="d-lg-none">
        <BurgerMenu />
      </MenuWrapper>
      <Layout>
        <StyledHero img={data.banner.childImageSharp.fluid} />
        <Container className="py-5">
          <Title title="Recordings" subtitle="Overview" />
          <hr />
          {data.recordings.edges.map(({ node }) => {
            return <RecordItem key={node.id} node={node} />
          })}
        </Container>
      </Layout>
    </>
  )
}

export const getRecordingData = graphql`
  query {
    recordings: allContentfulRecordings(
      sort: { fields: release, order: DESC }
    ) {
      totalCount
      edges {
        node {
          title
          release
          articelNumber
          link
          amazonLink
          content {
            content
          }
          id: contentful_id
          cover {
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
    banner: file(relativePath: { eq: "jun-record.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default recordings
