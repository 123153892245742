import React from "react"
import Image from "gatsby-image"
import { FaShoppingCart } from "react-icons/fa"

const RecordItem = ({ node }) => {
  return (
    <div className="row mt-5">
      <div className="col-sm-3">
        <div>
          <Image
            className="card-img-top"
            fluid={node.cover.fluid}
            alt="CD-Cover"
          />
        </div>
      </div>
      <div className="col-sm-9 align-self-xl-center">
        <div className="pt-4 pt-sm-0">
          <h6 className="card-title">{node.title}</h6>
          <p className="card-text">{node.content.content}</p>
          <p className="card-text">
            <a href={node.link} target="_blank" rel="noopener noreferrer">
              {node.articelNumber}
            </a>
          </p>
          <p className="card-text">
            <a target="_blank" rel="noopener noreferrer" href={node.amazonLink}>
              <FaShoppingCart /> Buy
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}

export default RecordItem
